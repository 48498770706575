import styled from 'styled-components'

import { Section } from 'components/general-styles'
import { theme } from 'styles/theme'

const { fontSizes, sizes, borderWidths, colors, media, space, radii } = theme

export const Blog = styled(Section)`
  width: 100%;

  .anchor-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    font-size: ${fontSizes['font-size-body-2']};
    background: ${colors['blue-background-strong-default']};
    color: ${colors['blue-text-strong']};
    margin-top: ${space['spacing-sm']};
    padding: 0 ${space['spacing-sm']};
    height: 48px;
    border-radius: ${radii['corner-radius-sm']};

    span {
      width: ${sizes['spacing-md']};
      height: ${sizes['spacing-md']};

      path {
        fill: ${colors['blue-text-strong']};
      }
    }
  }

  .anchor-button:hover {
    background: ${colors['blue-background-strong-hover']};
  }

  .anchor-button:hover::after {
    content: unset;
  }
`

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${space['spacing-sm']};
  gap: ${space['spacing-sm']};
  border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
  border-radius: ${space['spacing-xxs']};

  > p {
    font-size: 15px;
  }

  @media (${media.desktop}) {
    font-size: ${fontSizes['font-size-body-2']};
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.375rem;

  > div {
    position: relative;
    width: 100%;
    max-width: 6.25rem;
  }
`
