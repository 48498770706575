import { memo } from 'react'

import { Link, Text, Icon } from '@cash-me/react-components'
import NextImage from 'next/image'

import { Infos } from 'components/general-styles'
import { Grid, Col } from 'components/grid'
import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'

const Blog = memo(() => (
  <S.Blog>
    <Grid col={{ phone: 2, tablet: 12 }}>
      <Col start={{ desktop: 2 }} col={{ phone: 2, tablet: 12, desktop: 11 }}>
        <Infos>
          <Text
            className="subtitle"
            uppercase
            size="caption"
            color="var(--colors-neutral-text-default)"
            weight="medium"
          >
            Blog CashMe
          </Text>

          <Text as="h2" size="heading3" weight="medium">
            A{' '}
            <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
              CashMe
            </Text>{' '}
            na mídia
          </Text>
        </Infos>
      </Col>
      <Col start={{ desktop: 2 }} col={{ phone: 1, tablet: 4, desktop: 3 }}>
        <S.Post>
          <S.ImageWrapper>
            <div>
              <NextImage
                src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/logo/infomoney.png?updated=03072022`}
                alt="Logo da Infomoney"
                width="190"
                height="37"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto'
                }}
              />
            </div>
          </S.ImageWrapper>
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            CashMe facilita crédito para empresas
          </Text>
        </S.Post>
      </Col>
      <Col col={{ phone: 1, tablet: 4, desktop: 3 }}>
        <S.Post>
          <S.ImageWrapper>
            <div>
              <NextImage
                src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/logo/serasa.png?updated=03072022`}
                alt="Logo da Serasa"
                width="141"
                height="66"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto'
                }}
              />
            </div>
          </S.ImageWrapper>
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            CashMe: empréstimo a partir de R$ 50 mil com garantia de imóvel
          </Text>
        </S.Post>
      </Col>
      <Col col={{ phone: 2, tablet: 4, desktop: 3 }}>
        <S.Post>
          <S.ImageWrapper>
            <div>
              <NextImage
                src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/logo/estadao.png?updated=03072022`}
                alt="Logo do Estadao"
                width="183"
                height="41"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto'
                }}
              />
            </div>
          </S.ImageWrapper>
          <Text size="body2" color="var(--colors-neutral-text-weak)">
            Cyrela monta time de finanças e tech para dobrar volume da fintech CashMe
          </Text>
        </S.Post>
      </Col>
      <Col start={{ desktop: 2 }} col={{ phone: 2, tablet: 12, desktop: 11 }}>
        <Link
          className="anchor-button"
          color="primary"
          href="https://www.cashme.com.br/blog/"
          id="link"
          size="default"
          onClick={() => {
            pushDataLayer({
              category: 'Clique',
              action: 'Blog',
              label: 'Botão Veja mais Notícias'
            })
          }}
        >
          Ver mais notícias
          <Icon
            name="arrowRight"
            size={12}
          />
        </Link>
      </Col>
    </Grid>
  </S.Blog>
))

Blog.displayName = 'Blog'

export default Blog
